<template>
  <div>
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-blue">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <FormSelect
              v-model="filters.status"
              :options="commStatuses"
              label="Status"
              icon="donut_large"
            />
          </div>
          <div class="md-layout-item md-size-100">
            <md-button
              class="md-success md-sm w-100"
              @click="getCommissions()"
            >
              Filter
            </md-button>
            <md-button
              class="md-primary md-sm w-100"
              @click="downloadFiltered()"
            >
              Download XLS
            </md-button>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <div class="d-inline-block pull-right">
              <md-switch
                v-model="filters.show_fully_paid"
                @change="getCommissions()"
              >
                Show Fully Paid
              </md-switch>
            </div>
          </div>

          <div class="md-layout-item md-size-100 mt-4">
            <vue-table
              v-if="vTable.headers"
              ref="vtable"
              :values="vTable.values"
              :headers="vTable.headers"
              :actions="vTable.actions"
              :options="{
                tableClass: 'table',
              }"
              hidden-filters
              hidden-footer
              @changed="getCommissions"
              @onShowDetails="onShowDetails"
            />

            <div
              v-if="
                !loading && (!vTable.values.data || !vTable.values.data.length)
              "
              class="p-4 text-center"
            >
              There is no Commissions to show
            </div>
          </div>
        </div>
      </md-card-content>
    </md-card>

    <CommissionsDetails
      v-if="showDetails"
      :item="selItem"
      @close="closeDetails"
    />
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import { FormSelect } from '@/components/Inputs';

import moment from 'moment';
import downloadFile from '@/utils/downloadFile';
import CommissionsDetails from '@/components/Commissions/CommissionsDetails.vue';

export default {
  components: {
    VueTable,
    CommissionsDetails,
    FormSelect,
  },
  data() {
    return {
      loading: false,
      vTable: {
        headers: [
          {
            title: 'agent',
          },
          {
            title: 'student_number',
            mask: 'student id',
          },
          {
            title: 'full_name',
            mask: 'full name',
          },
          {
            title: 'payment_date',
            mask: 'payment date',
            dateFormat: true,
          },
          {
            title: 'instalment',
          },
          {
            title: 'payment_method',
            mask: 'payment method',
          },
          {
            title: 'income_total',
            mask: 'amount approved',
          },
          {
            title: 'paid_amount',
            mask: 'total received',
          },
          {
            title: 'package_price',
            mask: 'package price',
          },
          {
            title: 'commission',
          },
          {
            title: 'status',
          },
        ],
        actions: [
          {
            buttonClass: 'md-warning',
            tooltip: 'Show Details',
            callback: 'onShowDetails',
            icon: 'search',
          },
        ],
        values: {},
      },
      filters: {
        agent_id: null,
        show_fully_paid: false,
        status: 'ALL',
        show_submitted: false,
        student_id: null,
      },
      commStatuses: [
        { id: 'ALL', name: 'ALL' },
        { id: 'NON COMMISSIONABLE', name: 'NON COMMISSIONABLE' },
        { id: 'UNSUBMITTED', name: 'UNSUBMITTED' },
        { id: 'UNPAID', name: 'UNPAID' },
        { id: 'PAID', name: 'PAID' },
        { id: 'REJECTED', name: 'REJECTED' },
      ],
      showDetails: false,
      selItem: null,
    };
  },
  mounted() {
    if (this.userIsAgent && !this.userIsAgentsManager) {
      this.filters.agent_id = this.user.user_id;
    }
    this.filters.student_id = this.$route.params.id;
    this.$refs.vtable.init();
  },
  methods: {
    getCommissions() {
      this.loading = true;
      this.request(
        'agents/student_commissions',
        'get',
        { ...this.filters, per_page: 1000 },
        ({ data }) => {
          this.vTable.values = {
            current_page: 1,
            from: 1,
            to: 1,
            last_page: 1,
            per_page: 1,
            total: 1,
            data,
          };
        },
        () => {
          this.loading = false;
        },
      );
    },
    downloadFiltered() {
      this.loading = true;

      this.request(
        'agents/student_commissions',
        'download',
        { download: true, ...this.filters },
        ({ data }) => {
          const filename = `submitted_commissions_${moment().format(
            'YYYYMMDD-HHmmss',
          )}.xlsx`;
          downloadFile(data, filename);
          this.getCommissions();
        },
        () => {
          this.loading = false;
        },
      );
    },
    onShowDetails(item) {
      this.selItem = item;
      this.showDetails = true;
    },
    closeDetails() {
      this.selItem = null;
      this.showDetails = false;
    },
  },
};
</script>
